@keyframes grow {
  from {
    height: 0;
  }
  to {
    height: var(--target-height);
  }
}

/* Apply a different duration or delay if needed */
.column-animation {
  animation: grow 1.5s ease-out forwards;
}

@keyframes moveUp {
  from {
    transform: translateY(100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.movingUp {
  animation: moveUp 0.5s ease forwards;
}

.movingDown {
  animation: moveDown 0.5s ease forwards;
}




/* Custom border effects for 1st, 2nd, and 3rd places */
.border-1st {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0), /* Placeholder for transparency */
              0 0 0 20px url('https://i.ibb.co/g7hXkSF/ma.png') repeat;
}
.border-2nd {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0), /* Placeholder for transparency */
              0 0 0 20px url('https://i.ibb.co/g3SN9T7/gold-1.png') repeat;
}
.border-3rd {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0), /* Placeholder for transparency */
              0 0 0 20px url('https://i.ibb.co/25s6BG8/bronze-1.png') repeat;
}


.profile-wrapper {
  display: inline-block; /* Or flex, depending on your layout */
  position: relative;
  border-radius: 50%; /* Maintain the circular shape */
  padding: 5px; /* Adjust based on the desired border thickness */
}

.profile-image {
  display: block;
  width: 100%; /* Ensure the image fills the wrapper */
  height: auto;
  border-radius: 50%; /* Maintain the circular shape for the image */
}
