/* Add this to your global CSS file */
.text-gold {
    color: #dbbb02; /* Gold color */
  }
  
  .text-silver {
    color: #c0c0c0; /* Silver color */
  }
  
  .text-bronze {
    color: #cd7f32; /* Bronze color */
  }
  
