/* Simplified Toggle Switch Styles */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px; /* Adjust width as necessary */
    height: 22px; /* Adjust height as necessary */
  }
  
  .toggle-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* Default state color */
    transition: .4s;
    border-radius: 22px;
  }
  
  .toggle-label:before {
    position: absolute;
    content: "";
    height: 18px; /* Slightly smaller than the container for margin */
    width: 18px; /* Square shape */
    left: 2px;
    bottom: 2px; /* Adjusted to fit within the container */
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  .toggle-checkbox:checked + .toggle-label {
    background-color: #4D96FF; /* Active state color */
  }
  
  .toggle-checkbox:checked + .toggle-label:before {
    transform: translateX(18px); /* Adjusted to fit the new container size */
  }

  .hide-scrollbar {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  .custom-text-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

